body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loading-topbar {
  height: 40px;
  background-color: rgb(45, 60, 76);
  text-align: center;
  padding-top: 8px;
  padding-right: 32px;
}
.loading-body {
  padding: 24px;
  margin-top: 94px;
  line-height: 1.5;
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 600px) {
  .loading-body {
    margin-top: 106px;
  }
}

.mobile-only {
  display: block;
}

.desktop-only {
  display: none;
}

@media (min-width: 421px) {
  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: block;
  }
}
